* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

* *::before, * *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: 'Urbanist', sans-serif;
}

/* Linha de fundo */
::-webkit-scrollbar-track {
  background: #6B6B6B;
}

/* barra que se move */
::-webkit-scrollbar-thumb {
  background: #B9A4A4;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  border-radius: 5px;
}

/* hover barra */
::-webkit-scrollbar-thumb:hover {
  background: #8D6148;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

@font-face {
  font-family: 'GreatlyStamp';
  src: url("../font/GreatlyStamp.ttf") format("truetype");
}

.hero_padaria {
  background-image: url(../media/hero/fundo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  -webkit-animation: move 40s ease;
          animation: move 40s ease;
}

.hero_padaria .hero_padaria__container {
  width: 100%;
  padding-top: 21.25rem;
  padding-bottom: 26.25rem;
  padding-left: 10rem;
}

.hero_padaria .hero_padaria__container .logotipo {
  position: absolute;
  top: 2.5rem;
  left: 6.875rem;
}

.hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
  font-family: 'GreatlyStamp';
  font-size: 4.6875rem;
  color: #FFFFFF;
  font-weight: 400;
  position: relative;
  -webkit-transform: rotate(-3.88deg);
          transform: rotate(-3.88deg);
  line-height: 1;
}

.hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
  content: url(../media/hero/mach.svg);
  position: absolute;
  bottom: -6.875rem;
  left: -2.5rem;
  width: 100%;
  -webkit-transform: rotate(3.88deg);
          transform: rotate(3.88deg);
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .hero_padaria {
    background-image: url(../media/hero/fundo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 15.25rem;
    padding-bottom: 20.25rem;
    padding-left: 10rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    position: absolute;
    top: 2.5rem;
    left: 6.875rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-family: 'GreatlyStamp';
    font-size: 4rem;
    color: #FFFFFF;
    font-weight: 400;
    position: relative;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
    line-height: 1;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -6.875rem;
    left: -2.5rem;
    width: 100%;
    -webkit-transform: rotate(3.88deg);
            transform: rotate(3.88deg);
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1439px) {
  .hero_padaria {
    background-image: url(../media/hero/fundo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 15.25rem;
    padding-bottom: 20.25rem;
    padding-left: 8rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    position: absolute;
    top: 2.5rem;
    left: 6.875rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-family: 'GreatlyStamp';
    font-size: 3.75rem;
    color: #FFFFFF;
    font-weight: 400;
    position: relative;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
    line-height: 1;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -6.875rem;
    left: -2.5rem;
    width: 100%;
    -webkit-transform: rotate(3.88deg);
            transform: rotate(3.88deg);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1259px) {
  .hero_padaria {
    background-image: url(../media/hero/fundo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 15.25rem;
    padding-bottom: 20.25rem;
    padding-left: 6rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    position: absolute;
    top: 2.5rem;
    left: 6.875rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-family: 'GreatlyStamp';
    font-size: 3.375rem;
    color: #FFFFFF;
    font-weight: 400;
    position: relative;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
    line-height: 1;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -6.875rem;
    left: -2.5rem;
    width: 100%;
    -webkit-transform: rotate(3.88deg);
            transform: rotate(3.88deg);
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .hero_padaria {
    background-image: url(../media/hero/fundo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    overflow: hidden;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 15.25rem;
    padding-bottom: 20.25rem;
    padding-left: 5rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    position: absolute;
    top: 2.5rem;
    left: 6.875rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-family: 'GreatlyStamp';
    font-size: 3.375rem;
    color: #FFFFFF;
    font-weight: 400;
    position: relative;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
    line-height: 1;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -6.875rem;
    left: -8rem;
    width: 100%;
    -webkit-transform: rotate(3.88deg) scale(0.5);
            transform: rotate(3.88deg) scale(0.5);
  }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
  .hero_padaria {
    background-position: center;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 20rem;
    padding-bottom: 20rem;
    padding-left: 1.25rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    width: 100%;
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .hero_padaria .hero_padaria__container .logotipo img {
    max-height: 80px;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content {
    text-align: center;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-size: 2.875rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -4.875rem;
    left: .5rem;
    right: 0;
    width: 50%;
    -webkit-transform: rotate(3.88deg) scale(0.5);
            transform: rotate(3.88deg) scale(0.5);
  }
}

@media only screen and (min-width: 360px) and (max-width: 500px) {
  .hero_padaria {
    background-position: center;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 20rem;
    padding-bottom: 20rem;
    padding-left: 1.25rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    width: 100%;
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .hero_padaria .hero_padaria__container .logotipo img {
    max-height: 80px;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content {
    text-align: center;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-size: 2.625rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -4.875rem;
    left: -2rem;
    right: 0;
    width: 50%;
    -webkit-transform: rotate(3.88deg) scale(0.5);
            transform: rotate(3.88deg) scale(0.5);
  }
}

@media only screen and (min-width: 0px) and (max-width: 359px) {
  .hero_padaria {
    background-position: center;
  }
  .hero_padaria .hero_padaria__container {
    width: 100%;
    padding-top: 15rem;
    padding-bottom: 15rem;
    padding-left: 0.85rem;
    padding-left: 0.85rem;
  }
  .hero_padaria .hero_padaria__container .logotipo {
    width: 100%;
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .hero_padaria .hero_padaria__container .logotipo img {
    max-height: 80px;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content {
    text-align: center;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title {
    font-size: 2.375rem;
  }
  .hero_padaria .hero_padaria__container .hero_padaria__container__content h1.hero_padaria__container__content__title::after {
    content: url(../media/hero/mach.svg);
    position: absolute;
    bottom: -4.875rem;
    left: -2rem;
    right: 0;
    width: 50%;
    -webkit-transform: rotate(3.88deg) scale(0.4);
            transform: rotate(3.88deg) scale(0.4);
  }
}

.FabricoProprio {
  background-image: url(../media/fabricoproprio/fundobroa.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 38%;
  min-height: 100vh;
  position: relative;
}

.FabricoProprio.sobremesa {
  background-image: url(../media/fabricoproprio/sobremesa.jpg);
}

.FabricoProprio svg.rotate {
  position: absolute;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: rotate 50s ease infinite;
          animation: rotate 50s ease infinite;
}

.FabricoProprio .FabricoProprio__container {
  padding-top: 160px;
  padding-bottom: 200px;
  padding-left: 250px;
}

.FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
  max-width: 50%;
}

.FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
  font-family: 'GreatlyStamp';
  font-size: 4.6875rem;
  color: #8D6148;
  font-weight: 400;
  position: relative;
  line-height: 0.6;
  margin-bottom: 4rem;
}

.FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
  content: url(../media/fabricoproprio/machescuro.svg);
  position: absolute;
  bottom: -50px;
  left: -40px;
  width: 100%;
}

.FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
  max-width: 470px;
  padding-top: 1rem;
  font-size: 1rem;
  color: #8D6148;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .FabricoProprio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 38%;
    min-height: 100vh;
    position: relative;
  }
  .FabricoProprio svg.rotate {
    position: absolute;
    top: 0%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: rotate 50s ease infinite;
            animation: rotate 50s ease infinite;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 10rem;
    padding-bottom: 12.5rem;
    padding-left: 10.625rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 50%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-family: 'GreatlyStamp';
    font-size: 4rem;
    color: #8D6148;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    margin-bottom: 4rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -3.125rem;
    left: -2.5rem;
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: 470px;
    padding-top: 1rem;
    font-size: 1rem;
    color: #8D6148;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1439px) {
  .FabricoProprio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 40%;
    min-height: 100vh;
    position: relative;
  }
  .FabricoProprio svg.rotate {
    position: absolute;
    top: 0%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: rotate 50s ease infinite;
            animation: rotate 50s ease infinite;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 10rem;
    padding-bottom: 12.5rem;
    padding-left: 8rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 40%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-family: 'GreatlyStamp';
    font-size: 4rem;
    color: #8D6148;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    margin-bottom: 4rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -3.125rem;
    left: -2.5rem;
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: 440px;
    padding-top: 1rem;
    font-size: 1rem;
    color: #8D6148;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1259px) {
  .FabricoProprio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 40%;
    min-height: 100vh;
    position: relative;
  }
  .FabricoProprio svg.rotate {
    position: absolute;
    top: 0%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: rotate 50s ease infinite;
            animation: rotate 50s ease infinite;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 8rem;
    padding-bottom: 12.5rem;
    padding-left: 6rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 40%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-family: 'GreatlyStamp';
    font-size: 4rem;
    color: #8D6148;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    margin-bottom: 4rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -3.125rem;
    left: -2.5rem;
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: 440px;
    padding-top: 1rem;
    font-size: 1rem;
    color: #8D6148;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .FabricoProprio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 40%;
    min-height: 100vh;
    position: relative;
  }
  .FabricoProprio svg.rotate {
    position: absolute;
    top: 0%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: rotate 50s ease infinite;
            animation: rotate 50s ease infinite;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 8rem;
    padding-bottom: 12.5rem;
    padding-left: 4rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 40%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-family: 'GreatlyStamp';
    font-size: 4rem;
    color: #8D6148;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    margin-bottom: 4rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -3.125rem;
    left: -2.5rem;
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: 440px;
    padding-top: 1rem;
    font-size: 1rem;
    color: #8D6148;
  }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
  .FabricoProprio {
    background-image: url(../media/fabricoproprio/broamobile.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0%;
    min-height: 130vh;
    position: relative;
  }
  .FabricoProprio.sobremesa {
    background-image: url(../media/fabricoproprio/sobremesaMobile.jpg);
  }
  .FabricoProprio svg.rotate {
    display: none;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 5rem;
    padding-bottom: 10rem;
    padding-left: 1.25rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 70%;
    margin: 0 auto;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-size: 4rem;
    text-align: center;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -50px;
    left: -2rem;
    right: 0;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: unset;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 360px) and (max-width: 500px) {
  .FabricoProprio {
    background-color: #EFD1AF;
    background-image: unset;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0%;
    min-height: unset;
    position: relative;
    -webkit-transition: background-color .6s ease;
    transition: background-color .6s ease;
  }
  .FabricoProprio.sobremesa {
    background-image: unset;
    background-color: #BBD2D8;
  }
  .FabricoProprio svg.rotate {
    display: none;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 1.25rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 70%;
    margin: 0 auto;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-size: 4rem;
    text-align: center;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -50px;
    left: -2rem;
    right: 0;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: unset;
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 359px) {
  .FabricoProprio {
    background-image: unset;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 0%;
    min-height: unset;
    position: relative;
    background-color: #EFD1AF;
    -webkit-transition: background-color .6s ease;
    transition: background-color .6s ease;
  }
  .FabricoProprio.sobremesa {
    background-image: unset;
    background-color: #BBD2D8;
  }
  .FabricoProprio svg.rotate {
    display: none;
  }
  .FabricoProprio .FabricoProprio__container {
    padding-top: 5rem;
    padding-bottom: 10rem;
    padding-left: 1.25rem;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left {
    max-width: 70%;
    margin: 0 auto;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1 {
    font-size: 4rem;
    text-align: center;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left h1::after {
    content: url(../media/fabricoproprio/machescuro.svg);
    position: absolute;
    bottom: -50px;
    left: -2rem;
    right: 0;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    width: 100%;
  }
  .FabricoProprio .FabricoProprio__container .FabricoProprio__container__content_left p {
    max-width: unset;
    font-size: 0.875rem;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

.GridProdutos {
  margin-top: -5rem;
  overflow: hidden;
}

.GridProdutos .GridProdutos__container {
  padding-left: 245px;
  padding-right: 245px;
}

.GridProdutos .GridProdutos__container .GridProdutos_heading {
  margin-bottom: 2rem;
}

.GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
  font-family: 'GreatlyStamp';
  font-size: 4.6875rem;
  color: #B9A4A4;
  font-weight: 400;
  position: relative;
  line-height: 1;
  -webkit-transform: rotate(-3.88deg);
          transform: rotate(-3.88deg);
  max-width: 40%;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters {
  margin-top: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters .GridProdutos_filtro {
  margin-right: 2rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: #c0bfbf36;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters .GridProdutos_filtro p {
  font-size: 1.125rem;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters .GridProdutos_filtro.padaria:hover {
  background-color: #E2C7B2;
  color: #FFFFFF;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters .GridProdutos_filtro.pastelaria:hover {
  background-color: #C1D8DE;
  color: #FFFFFF;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters .GridProdutos_filtro.activePadaria {
  background-color: #E2C7B2;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #FFFFFF;
}

.GridProdutos .GridProdutos__container .GridProdutos_filters .GridProdutos_filtro.activePastelaria {
  background-color: #C1D8DE;
  color: #FFFFFF;
}

.GridProdutos .GridProdutos__container .GridProdutos__content {
  padding-top: 5rem;
  padding-bottom: 15rem;
}

.GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  gap: 8rem;
  justify-items: center;
  text-align: center;
}

.GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto {
  width: 100%;
}

.GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 350px;
  max-height: 350px;
}

.GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
  padding-top: 30px;
  font-family: 'GreatlyStamp';
  font-size: 2.625rem;
  color: #C6B4B3;
  font-weight: 400;
}

.GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
  max-width: 90%;
  color: #6B6B6B;
  cursor: pointer;
  position: relative;
}

.GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  border-bottom: 2px solid #C6B4B3;
  width: 120px;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .GridProdutos {
    margin-top: -10rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    font-family: 'GreatlyStamp';
    font-size: 4.6875rem;
    color: #B9A4A4;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 8rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-size: 2.625rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1439px) {
  .GridProdutos {
    margin-top: -6rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    font-family: 'GreatlyStamp';
    font-size: 4.375rem;
    color: #B9A4A4;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 8rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-size: 2.625rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1259px) {
  .GridProdutos {
    margin-top: -6rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    font-family: 'GreatlyStamp';
    font-size: 4rem;
    color: #B9A4A4;
    font-weight: 400;
    position: relative;
    line-height: 0.6;
    -webkit-transform: rotate(-3.88deg);
            transform: rotate(-3.88deg);
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 8rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-size: 2.625rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 851px) and (max-width: 1023px) {
  .GridProdutos {
    margin-top: 5rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 4rem;
    padding-right: 4rem;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    max-width: 50%;
    font-size: 4rem;
    line-height: 0.8;
    text-align: left;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_filters {
    margin-top: 2.5rem;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 4rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto {
    width: 100%;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 350px;
    max-height: 350px;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-family: 'GreatlyStamp';
    font-size: 2.625rem;
    color: #C6B4B3;
    font-weight: 400;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 700px) and (max-width: 850px) {
  .GridProdutos {
    margin-top: 5rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    max-width: 70%;
    font-size: 4rem;
    line-height: 0.7;
    text-align-last: left;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_filters {
    margin-top: 2.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 4rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto {
    width: 100%;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 350px;
    max-height: 350px;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-family: 'GreatlyStamp';
    font-size: 2.625rem;
    color: #C6B4B3;
    font-weight: 400;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 501px) and (max-width: 699px) {
  .GridProdutos {
    margin-top: 5rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    min-width: 95vw;
    font-size: 4rem;
    line-height: 0.8;
    text-align-last: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_filters {
    margin-top: 2.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 4rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto {
    width: 100%;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 350px;
    max-height: 350px;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-family: 'GreatlyStamp';
    font-size: 2.625rem;
    color: #C6B4B3;
    font-weight: 400;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 360px) and (max-width: 500px) {
  .GridProdutos {
    margin-top: 5rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    max-width: 100%;
    font-size: 3.75rem;
    line-height: 0.7;
    text-align: left;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_filters {
    margin-top: 2.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 4rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto {
    width: 100%;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 350px;
    max-height: 350px;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-family: 'GreatlyStamp';
    font-size: 2.625rem;
    color: #C6B4B3;
    font-weight: 400;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

@media only screen and (min-width: 0px) and (max-width: 359px) {
  .GridProdutos {
    margin-top: 5rem;
    overflow: hidden;
  }
  .GridProdutos .GridProdutos__container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading {
    margin-bottom: 2rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_heading h1 {
    max-width: 100%;
    font-size: 3.375rem;
    line-height: 0.7;
    text-align: left;
  }
  .GridProdutos .GridProdutos__container .GridProdutos_filters {
    margin-top: 2.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    gap: 4rem;
    justify-items: center;
    text-align: center;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto {
    width: 100%;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .imagemProduto img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 350px;
    max-height: 350px;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto h2 {
    padding-top: 30px;
    font-family: 'GreatlyStamp';
    font-size: 2.625rem;
    color: #C6B4B3;
    font-weight: 400;
  }
  .GridProdutos .GridProdutos__container .GridProdutos__content .GridProdutos__content_grid .GridProdutos__content_grid_item .infoProduto p {
    max-width: 100%;
    color: #6B6B6B;
  }
}

.Footer {
  background-color: #282828;
  height: 100%;
  padding-top: 5rem;
}

.Footer .Footer__container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.Footer .Footer__container .Footer__container__left, .Footer .Footer__container .Footer__container__right {
  width: 50%;
}

.Footer .Footer__container .Footer__container__left img {
  margin-top: -5rem;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form h3 {
  font-family: 'GreatlyStamp';
  color: #FFFFFF;
  font-size: 2.5rem;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form input, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea {
  margin-top: 1rem;
  padding: 0.5rem;
  outline: none;
  border: none;
  background-color: #d9d9d980;
  color: #FFFFFF;
  font-size: 16px;
  border-radius: 5px;
  font-family: 'Urbanist',sans-serif;
  width: 100%;
  max-width: 450px;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form input::-webkit-input-placeholder, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea::-webkit-input-placeholder {
  color: #FFFFFF;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form input:-ms-input-placeholder, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea:-ms-input-placeholder {
  color: #FFFFFF;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form input::-ms-input-placeholder, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea::-ms-input-placeholder {
  color: #FFFFFF;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form input::placeholder, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea::placeholder {
  color: #FFFFFF;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form button {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  outline: none;
  border: none;
  background-color: #E8B856;
  color: #FFFFFF;
  font-size: 16px;
  border-radius: 5px;
  font-family: 'Urbanist',sans-serif;
  cursor: pointer;
  -webkit-transition: background-color .6s ease;
  transition: background-color .6s ease;
}

.Footer .Footer__container .Footer__container__right .Footer__container__right_form form button:hover {
  background-color: #B9A4A4;
}

.Footer .Footer__container .Footer__container__right .contactlinks {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Footer .Footer__container .Footer__container__right .contactlinks ul li {
  color: #FFFFFF;
  font-size: 1.25rem;
  padding-top: 7px;
  font-weight: 400;
}

.Footer .Footer__container .Footer__container__right .contactlinks ul li a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: color .6s ease;
  transition: color .6s ease;
}

.Footer .Footer__container .Footer__container__right .contactlinks ul li a:hover {
  color: #B9A4A4;
}

.Footer .Footer__container .Footer__container__right .contactlinks ul li::marker {
  color: #E8B856;
  font-size: 25px;
}

.Footer .Footer__copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 2rem;
}

.Footer .Footer__copyright .item p, .Footer .Footer__copyright .item a, .Footer .Footer__copyright .item span {
  color: #FFFFFF;
  text-decoration: none;
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  .Footer {
    background-color: #282828;
    height: 100%;
  }
  .Footer .Footer__container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .Footer .Footer__container .Footer__container__left, .Footer .Footer__container .Footer__container__right {
    width: 50%;
  }
  .Footer .Footer__container .Footer__container__left img {
    width: 100%;
    margin-top: -3rem;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form h3 {
    font-size: 2.5rem;
    font-weight: 400;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form form input, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea {
    max-width: 450px;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form form button {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li {
    color: #FFFFFF;
    font-size: 1rem;
    padding-top: 2px;
    font-weight: 400;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li::marker {
    color: #E8B856;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1439px) {
  .Footer {
    background-color: #282828;
    height: 100%;
  }
  .Footer .Footer__container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .Footer .Footer__container .Footer__container__left, .Footer .Footer__container .Footer__container__right {
    width: 50%;
  }
  .Footer .Footer__container .Footer__container__left img {
    width: 100%;
    margin-top: -3rem;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form h3 {
    font-size: 2.5rem;
    font-weight: 400;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form form input, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea {
    max-width: 450px;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form form button {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li {
    color: #FFFFFF;
    font-size: 1rem;
    padding-top: 2px;
    font-weight: 400;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li::marker {
    color: #E8B856;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1259px) {
  .Footer {
    background-color: #282828;
    height: 100%;
  }
  .Footer .Footer__container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .Footer .Footer__container .Footer__container__left, .Footer .Footer__container .Footer__container__right {
    width: 50%;
  }
  .Footer .Footer__container .Footer__container__left img {
    width: 100%;
    margin-top: -3rem;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form h3 {
    font-size: 2.5rem;
    font-weight: 400;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form form input, .Footer .Footer__container .Footer__container__right .Footer__container__right_form form textarea {
    max-width: 450px;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form form button {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li {
    color: #FFFFFF;
    font-size: 1rem;
    padding-top: 2px;
    font-weight: 400;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li::marker {
    color: #E8B856;
    font-size: 20px;
  }
}

@media only screen and (min-width: 661px) and (max-width: 1023px) {
  .Footer {
    padding-top: 5rem;
    text-align: center;
  }
  .Footer .Footer__container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Footer .Footer__container .Footer__container__left, .Footer .Footer__container .Footer__container__right {
    width: 100%;
  }
  .Footer .Footer__container .Footer__container__left {
    display: none;
  }
  .Footer .Footer__container .Footer__container__right {
    padding-top: 2rem;
    padding-inline: 1.25rem;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form h3 {
    line-height: 1;
    font-size: 2.5rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul {
    padding-left: 1.25rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li {
    font-size: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li::marker {
    font-size: 20px;
  }
  .Footer .Footer__copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    padding-bottom: 3rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 660px) {
  .Footer {
    padding-top: 5rem;
  }
  .Footer .Footer__container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Footer .Footer__container .Footer__container__left, .Footer .Footer__container .Footer__container__right {
    width: 100%;
  }
  .Footer .Footer__container .Footer__container__left img {
    width: 100%;
  }
  .Footer .Footer__container .Footer__container__right {
    padding-top: 2rem;
    padding-inline: 1.25rem;
  }
  .Footer .Footer__container .Footer__container__right .Footer__container__right_form h3 {
    line-height: 1;
    font-size: 2.5rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul {
    padding-left: 1.25rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li {
    font-size: 1rem;
  }
  .Footer .Footer__container .Footer__container__right .contactlinks ul li::marker {
    font-size: 20px;
  }
  .Footer .Footer__copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    padding-bottom: 3rem;
  }
}

.PopupDownload {
  display: none;
  position: fixed;
  top: 0%;
  left: 0;
  min-height: 100vh;
  width: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.PopupDownload.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translate(0);
          transform: translate(0);
  background-color: rgba(40, 40, 46, 0.899);
}

.PopupDownload.active .PopupDownload__container {
  max-width: 500px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: #FFFFFF;
  padding: 4rem 4rem;
  position: relative;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 2rem;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close .logo {
  position: absolute;
  top: 20px;
  left: 20px;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close .logo img {
  -webkit-filter: invert(1);
          filter: invert(1);
  max-width: 80px;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close .close {
  position: absolute;
  top: 5px;
  right: 20px;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close .close p {
  color: #6B6B6B;
  font-size: 3rem;
  cursor: pointer;
  -webkit-transition: color .6s ease;
  transition: color .6s ease;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close .close p:hover {
  color: #000;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .headingForm {
  padding-top: 1rem;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .headingForm h1 {
  font-size: 2rem;
  text-align: center;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .headingForm p {
  padding-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario {
  padding-top: 1.25rem;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario label, .PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario input {
  display: block;
  width: 100%;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario input {
  padding: 4px;
  border: 1px solid #C6B4B3;
  background-color: #3d3c3c;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 16px;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario input::-webkit-input-placeholder {
  font-size: 13px;
  color: #a2a2a2;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario input:-ms-input-placeholder {
  font-size: 13px;
  color: #a2a2a2;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario input::-ms-input-placeholder {
  font-size: 13px;
  color: #a2a2a2;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .formulario input::placeholder {
  font-size: 13px;
  color: #a2a2a2;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .PopupDownload__container_content_download {
  padding-top: 1.5rem;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .PopupDownload__container_content_download input {
  background-color: #c7a07c;
  padding: 8px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #FFFFFF;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  font-size: 0.9rem;
  -webkit-appearance: none;
  -webkit-transition: background-color .6s ease;
  transition: background-color .6s ease;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .PopupDownload__container_content_download input:hover {
  background-color: #644123;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .AfterSubmit {
  text-align: center;
  padding-top: 20px;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .AfterSubmit.dnone {
  display: none;
}

.PopupDownload.active .PopupDownload__container .PopupDownload__container_content .AfterSubmit a {
  padding: 6px 20px;
  -webkit-appearance: none;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: #C6B4B3;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
  .PopupDownload.active .PopupDownload__container .PopupDownload__container_content .logo_close .logo img {
    max-width: 60px;
  }
  .PopupDownload.active .PopupDownload__container .PopupDownload__container_content .headingForm h1 {
    font-size: 1.6rem;
  }
  .PopupDownload.active .PopupDownload__container .PopupDownload__container_content .headingForm p {
    font-size: 0.9rem;
  }
  .PopupDownload.active .PopupDownload__container .PopupDownload__container_content .PopupDownload__container_content_download input {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 450px) {
  .PopupDownload.active .PopupDownload__container {
    max-width: 450px;
    padding: 4rem 2rem;
    margin-inline: 20px;
  }
}

.cookieconsent {
  -webkit-box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  height: 80px;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 1rem 1rem 1rem;
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  opacity: 0;
}

.cookieconsent #cookieimg {
  position: absolute;
  top: -25px;
}

.cookieconsent a {
  text-decoration: none;
  color: #000;
}

.cookieconsent #pol {
  margin-right: 1rem;
  display: inline-block;
}

.cookieconsent #aceitar {
  background-color: #B9A4A4;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: .6s ease;
  transition: .6s ease;
}

.cookieconsent #aceitar:hover {
  background-color: #EBC565;
}

.NewFade {
  -webkit-animation: fadeIn2 2s ease forwards;
          animation: fadeIn2 2s ease forwards;
}

.OutFade {
  -webkit-animation: fadeOut2 1s ease forwards;
          animation: fadeOut2 1s ease forwards;
}

@-webkit-keyframes fadeIn2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeOut2 {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOut2 {
  0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@media only screen and (max-width: 900px) {
  .cookieconsent {
    padding: 2rem 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  .cookieconsent .texto {
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
  }
  .cookieconsent .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 100%;
    padding-top: 20px;
  }
}

.Politicas {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-inline: 12rem;
}

.Politicas img {
  background: black;
  padding: 10px;
  position: fixed;
  top: 5px;
  left: 5px;
  width: 125px;
  border-radius: 80vmax;
  aspect-ratio: 1;
}

.Politicas p {
  padding-top: 1.5rem;
  font-size: 1rem;
}

.Politicas ol, .Politicas ul {
  list-style: disc;
  margin-left: 1rem;
}

.Politicas ol li, .Politicas ul li {
  padding-top: 0.5rem;
}

.Politicas h1 {
  font-size: 3rem;
  color: #B9A4A4;
}

.Politicas h2 {
  font-size: 2.6rem;
}

.Politicas h3 {
  font-size: 2.2rem;
}

.Politicas h4 {
  font-size: 1.8rem;
}

.Politicas h5 {
  font-size: 1.5rem;
}

.Politicas h1, .Politicas h2, .Politicas h3, .Politicas h4, .Politicas h5 {
  color: #8D6148;
}

@media only screen and (max-width: 600px) {
  .Politicas {
    padding-top: 10rem;
    padding-bottom: 5rem;
    padding-inline: 2rem;
  }
  .Politicas img {
    background: black;
    padding: 10px;
    position: fixed;
    top: 5px;
    left: 5px;
    width: 80px;
    border-radius: 80vmax;
    aspect-ratio: 1;
  }
  .Politicas p {
    padding-top: 1.5rem;
    font-size: 1rem;
  }
  .Politicas ol, .Politicas ul {
    list-style: disc;
    margin-left: 1rem;
  }
  .Politicas ol li, .Politicas ul li {
    padding-top: 0.5rem;
  }
  .Politicas h1 {
    font-size: 2.5rem;
  }
  .Politicas h2 {
    font-size: 2.2rem;
  }
  .Politicas h3 {
    font-size: 2rem;
  }
  .Politicas h4 {
    font-size: 1.6rem;
  }
  .Politicas h5 {
    font-size: 1.2rem;
  }
}
