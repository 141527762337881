

$castanhoEscuro : #8D6148;
$tomPastel : #B9A4A4;
$titulosProdutos : #C6B4B3;
$textos : #6B6B6B;
$branco : #FFFFFF;
$dourado : #E8B856;
$fundoInputs : #d9d9d980;



$azulSobremesa : #C1D8DE;
$tituloProdutoSobremesa : #B3C4C6;

