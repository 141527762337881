
.cookieconsent{
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 80px;
    padding-right: 60px;
    padding-left: 60px;
    justify-content: space-between;
    margin: 0 1rem 1rem 1rem;
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: stretch;
    opacity: 0;
    


    #cookieimg{
        position: absolute;
        top: -25px;
    }

    a{
        text-decoration: none;
        color: #000;
    }

    #pol{
        margin-right: 1rem;
        display: inline-block;
    }

    #aceitar{
        background-color: $tomPastel;
        padding: 0.5rem 1.5rem;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        display: inline-block;
        transition: .6s ease;
        &:hover{
            background-color: #EBC565;
            
        }
    }
     
}


.NewFade{
    animation: fadeIn2 2s ease forwards;
}


.OutFade{
    animation: fadeOut2 1s ease forwards;
}

@keyframes fadeIn2 {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

@keyframes fadeOut2 {
0% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}
100% {

    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}
}


@media only screen and (max-width:900px){
    .cookieconsent{

        padding: 2rem 1rem;
        flex-direction: column;
        height: auto;
    
        .texto{
            text-align: center;
            max-width: 70%;
            margin: 0 auto;
        }
        #cookieimg{
           
        }
    
        .buttons{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            padding-top: 20px;
        }

         
    }
}