.GridProdutos{
    margin-top: -5rem;
    overflow: hidden;

    .GridProdutos__container{

        padding-left: 245px;
        padding-right: 245px;

        .GridProdutos_heading{

            h1{
                font-family: 'GreatlyStamp';
                @include fs(75);
                color: $tomPastel;
                font-weight: 400;
                position: relative;
                line-height: 1;
                transform: rotate(-3.88deg);
                max-width: 40%;

            }
            margin-bottom: 2rem;
        }

        .GridProdutos_filters{
            margin-top: 5rem;
            display: flex;
            align-items: center;
            
            .GridProdutos_filtro{
                margin-right: 2rem;
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                padding: 0.5rem 1rem;
                border-radius: 20px;
                background-color: #c0bfbf36;


                p{
                    @include fs(18);
                }

                &.padaria{
                    &:hover{
                        background-color: #E2C7B2;
                        color: $branco;
                    }
                }
                &.pastelaria{
                    &:hover{
                        background-color: #C1D8DE;
                        color: $branco;
                    }
                }

                &.activePadaria{
                    background-color: #E2C7B2;
                    padding: 0.5rem 1rem;
                    border-radius: 20px;
                    color: $branco;

                }


                &.activePastelaria{
                    background-color: #C1D8DE;
                   
                    color: $branco;

                 
                }

               

            }
            
        }

        .GridProdutos__content{
            padding-top: 5rem;
            padding-bottom: 15rem;

            .GridProdutos__content_grid{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr 1fr;
                gap: 8rem;
                justify-items: center;
                text-align: center;

                .GridProdutos__content_grid_item{

                    .imagemProduto{
                        width: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            max-width: 350px;
                            max-height: 350px;
                        }
                    }
                    .infoProduto{
                        h2{
                            padding-top: 30px;
                            font-family: 'GreatlyStamp';
                            @include fs(42);
                            color: $titulosProdutos;
                            font-weight: 400;
                        }

                        p{
                            max-width: 90%;
                            color: $textos;
                            cursor: pointer;
                            position: relative;

                            &::after{
                                content: '';
                                position: absolute;
                                bottom: -3px;
                                left: 0;
                                right: 0;
                                top: 0;
                                margin: 0 auto;
                                border-bottom: 2px solid $titulosProdutos;
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }

    }



}


@media only screen and (min-width:1440px) and (max-width:1600px){

    .GridProdutos{
        margin-top: -10rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 10rem;
            padding-right: 10rem;
    
            .GridProdutos_heading{
    
                h1{
                    font-family: 'GreatlyStamp';
                    @include fs(75);
                    color: $tomPastel;
                    font-weight: 400;
                    position: relative;
                    line-height: 0.6;
                    transform: rotate(-3.88deg);
                    
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 8rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    

                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                @include fs(42);

                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }

}

@media only screen and (min-width:1260px) and (max-width:1439px){

    .GridProdutos{
        margin-top: -6rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 8rem;
            padding-right: 8rem;
    
            .GridProdutos_heading{
    
                h1{
                    font-family: 'GreatlyStamp';
                    @include fs(70);
                    color: $tomPastel;
                    font-weight: 400;
                    position: relative;
                    line-height: 0.6;
                    transform: rotate(-3.88deg);
                    
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 8rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    

                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                @include fs(42);

                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }

}

@media only screen and (min-width:1024px) and (max-width:1259px){

    .GridProdutos{
        margin-top: -6rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 6rem;
            padding-right: 6rem;
    
            .GridProdutos_heading{
    
                h1{
                    font-family: 'GreatlyStamp';
                    @include fs(64);
                    color: $tomPastel;
                    font-weight: 400;
                    position: relative;
                    line-height: 0.6;
                    transform: rotate(-3.88deg);
                    
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 8rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    

                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                @include fs(42);

                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }

}

@media only screen and (min-width:851px) and (max-width:1023px){


    .GridProdutos{
        margin-top: 5rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 4rem;
            padding-right: 4rem;
            text-align: center;

            .GridProdutos_heading{
    
                h1{
                    max-width: 50%;
                    @include fs(64);
                    line-height: 0.8;
                    text-align: left;
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos_filters{
                margin-top: 2.5rem;                
                justify-content: left;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 4rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    
                        .imagemProduto{
                            width: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                max-width: 350px;
                                max-height: 350px;
                            }
                        }
                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                font-family: 'GreatlyStamp';
                                @include fs(42);
                                color: $titulosProdutos;
                                font-weight: 400;
                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }




}

@media only screen and (min-width:700px) and (max-width:850px){


    .GridProdutos{
        margin-top: 5rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            text-align: center;

            .GridProdutos_heading{
    
                h1{
                    max-width: 70%;
                    @include fs(64);
                    line-height: 0.7;
                    text-align-last: left;
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos_filters{
                margin-top: 2.5rem;                
                justify-content: center;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 4rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    
                        .imagemProduto{
                            width: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                max-width: 350px;
                                max-height: 350px;
                            }
                        }
                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                font-family: 'GreatlyStamp';
                                @include fs(42);
                                color: $titulosProdutos;
                                font-weight: 400;
                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }




}


@media only screen and (min-width:501px) and (max-width:699px){


    .GridProdutos{
        margin-top: 5rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            text-align: center;

            .GridProdutos_heading{
    
                h1{
                    min-width: 95vw;
                    @include fs(64);
                    line-height: 0.8;
                    text-align-last: center;
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos_filters{
                margin-top: 2.5rem;                
                justify-content: center;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 4rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    
                        .imagemProduto{
                            width: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                max-width: 350px;
                                max-height: 350px;
                            }
                        }
                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                font-family: 'GreatlyStamp';
                                @include fs(42);
                                color: $titulosProdutos;
                                font-weight: 400;
                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }




}


@media only screen and (min-width:360px) and (max-width:500px){


    .GridProdutos{
        margin-top: 5rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            text-align: center;

            .GridProdutos_heading{
    
                h1{
                    max-width: 100%;
                    @include fs(60);
                    line-height: 0.7;
                    text-align: left;
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos_filters{
                margin-top: 2.5rem;                
                justify-content: center;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 4rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    
                        .imagemProduto{
                            width: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                max-width: 350px;
                                max-height: 350px;
                            }
                        }
                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                font-family: 'GreatlyStamp';
                                @include fs(42);
                                color: $titulosProdutos;
                                font-weight: 400;
                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }




}


@media only screen and (min-width:0px) and (max-width:359px){


    .GridProdutos{
        margin-top: 5rem;
        overflow: hidden;
    
        .GridProdutos__container{
    
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            text-align: center;

            .GridProdutos_heading{
    
                h1{
                    max-width: 100%;
                    @include fs(54);
                    line-height: 0.7;
                    text-align: left;
                }
                margin-bottom: 2rem;
            }
    
            .GridProdutos_filters{
                margin-top: 2.5rem;                
                justify-content: center;
            }
    
            .GridProdutos__content{
                padding-top: 5rem;
                padding-bottom: 15rem;
    
                .GridProdutos__content_grid{
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: 1fr 1fr;
                    gap: 4rem;
                    justify-items: center;
                    text-align: center;
    
                    .GridProdutos__content_grid_item{
    
                        .imagemProduto{
                            width: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                max-width: 350px;
                                max-height: 350px;
                            }
                        }
                        .infoProduto{
                            h2{
                                padding-top: 30px;
                                font-family: 'GreatlyStamp';
                                @include fs(42);
                                color: $titulosProdutos;
                                font-weight: 400;
                            }
    
                            p{
                                max-width: 100%;
                                color: $textos;
                            }
                        }
                    }
                }
            }
    
        }
    
    
    
    }




}