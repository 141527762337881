

.Politicas{

    img{
        background: black;
        padding: 10px;
        position: fixed;
        top: 5px;
        left: 5px;
        width: 125px;
        border-radius: 80vmax;
        aspect-ratio: 1;
    }

padding-top: 5rem;
padding-bottom: 5rem;
padding-inline: 12rem;

p{
    padding-top: 1.5rem;
    font-size: 1rem;
}

ol,ul{
    list-style: disc;
    margin-left: 1rem;

    li{
        padding-top: 0.5rem;
    }
}

h1{
    font-size: 3rem;
    color: $tomPastel;
}

h2{
    font-size: 2.6rem;
    
}

h3{
    font-size: 2.2rem;
    
}

h4{
    font-size: 1.8rem;
}

h5{
    font-size: 1.5rem;
}

h1,h2,h3,h4,h5{
    color: $castanhoEscuro;
}
}




@media only screen and (max-width:600px){



    .Politicas{

        img{
            background: black;
            padding: 10px;
            position: fixed;
            top: 5px;
            left: 5px;
            width: 80px;
            border-radius: 80vmax;
            aspect-ratio: 1;
        }

        padding-top: 10rem;
        padding-bottom: 5rem;
        padding-inline: 2rem;
    
        p{
            padding-top: 1.5rem;
            font-size: 1rem;
        }
    
        ol,ul{
            list-style: disc;
            margin-left: 1rem;

            li{
                padding-top: 0.5rem;
            }
        }

        h1{
            font-size: 2.5rem;
        }

        h2{
            font-size: 2.2rem;
            
        }

        h3{
            font-size: 2rem;
            
        }
        
        h4{
            font-size: 1.6rem;
        }

        h5{
            font-size: 1.2rem;
        }

   
    }

}
