.Footer{
    background-color: #282828;
    height: 100%;
    padding-top: 5rem;

    .Footer__container{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;


        .Footer__container__left,.Footer__container__right{
            width: 50%;
        }


        .Footer__container__left{
            img{
                margin-top: -5rem;
            }
        }


        .Footer__container__right{


            .Footer__container__right_form{

                h3{
                    font-family: 'GreatlyStamp';
                    color: $branco;
                    @include fs(40);
                }

                form{

                    input,textarea{
                        margin-top: 1rem;
                        padding: 0.5rem;
                        outline: none;
                        border: none;
                        background-color: $fundoInputs;
                        color: $branco;
                        font-size: 16px;
                        border-radius: 5px;
                        font-family: 'Urbanist',sans-serif;
                        &::placeholder{
                            color: $branco;
                        }
                        width: 100%;
                        max-width: 450px;

                    }

                    button{
                        margin-top: 1rem;
                        padding: 0.5rem 1.5rem;
                        outline: none;
                        border: none;
                        background-color: $dourado;
                        color: $branco;
                        font-size: 16px;
                        border-radius: 5px;
                        font-family: 'Urbanist',sans-serif;
                        cursor: pointer;

                        transition: background-color .6s ease;
                        &:hover{
                            background-color: $tomPastel;

                        }
                    }

                }

            }

            .contactlinks{
                padding-top: 2rem;
                padding-bottom: 2rem;
                ul{

                    li{
                        color: $branco;
                        @include fs(20);
                        padding-top: 7px;
                        font-weight: 400;
                        
                        a{
                            color: inherit;
                            text-decoration: none;
                            transition: color .6s ease;
                            &:hover{
                                color: $tomPastel;
                            }
                        }

                        &::marker{
                         
                            color: $dourado;
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }

    .Footer__copyright{
        display: flex;
        justify-content: space-between;
        padding: 1rem 2rem;

        .item{
            p,a,span{
                color: $branco;
                text-decoration: none;
            }
        }
    }
}


@media only screen and (min-width:1440px) and (max-width:1600px){

    .Footer{
        background-color: #282828;
        height: 100%;
    
        .Footer__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
    
    
            .Footer__container__left,.Footer__container__right{
                width: 50%;
            }
    
    
            .Footer__container__left{
                img{
                    width: 100%;
                    margin-top: -3rem;
                }
            }
    
    
            .Footer__container__right{
                
    
                .Footer__container__right_form{
    
                    h3{
                        @include fs(40);
                        font-weight: 400;
                    }
    
                    form{
    
                        input,textarea{                    
                            max-width: 450px;
                        }
    
                        button{
                            margin-top: 1rem;
                            padding: 0.5rem 1.5rem;                                                    
                        }
    
                    }
    
                }
    
                .contactlinks{
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    ul{
    
                        li{
                            color: $branco;
                            @include fs(16);
                            padding-top: 2px;
                            font-weight: 400;
                            
    
                            &::marker{
                                color: $dourado;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    

}

@media only screen and (min-width:1260px) and (max-width:1439px){

    .Footer{
        background-color: #282828;
        height: 100%;
    
        .Footer__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
    
    
            .Footer__container__left,.Footer__container__right{
                width: 50%;
            }
    
    
            .Footer__container__left{
                img{
                    width: 100%;
                    margin-top: -3rem;
                }
            }
    
    
            .Footer__container__right{
                
    
                .Footer__container__right_form{
    
                    h3{
                        @include fs(40);
                        font-weight: 400;
                    }
    
                    form{
    
                        input,textarea{                    
                            max-width: 450px;
                        }
    
                        button{
                            margin-top: 1rem;
                            padding: 0.5rem 1.5rem;                                                    
                        }
    
                    }
    
                }
    
                .contactlinks{
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    ul{
    
                        li{
                            color: $branco;
                            @include fs(16);
                            padding-top: 2px;
                            font-weight: 400;
                            
    
                            &::marker{
                                color: $dourado;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    

}

@media only screen and (min-width:1024px) and (max-width:1259px){

    .Footer{
        background-color: #282828;
        height: 100%;
    
        .Footer__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
    
    
            .Footer__container__left,.Footer__container__right{
                width: 50%;
            }
    
    
            .Footer__container__left{
                img{
                    width: 100%;
                    margin-top: -3rem;
                }
            }
    
    
            .Footer__container__right{
                
    
                .Footer__container__right_form{
    
                    h3{
                        @include fs(40);
                        font-weight: 400;
                    }
    
                    form{
    
                        input,textarea{                    
                            max-width: 450px;
                        }
    
                        button{
                            margin-top: 1rem;
                            padding: 0.5rem 1.5rem;                                                    
                        }
    
                    }
    
                }
    
                .contactlinks{
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    ul{
    
                        li{
                            color: $branco;
                            @include fs(16);
                            padding-top: 2px;
                            font-weight: 400;
                            
    
                            &::marker{
                                color: $dourado;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    

}


@media only screen and (min-width:661px) and (max-width:1023px){

    .Footer{
        padding-top: 5rem;
        text-align: center;
    
        .Footer__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            flex-direction: column;
    
    
            .Footer__container__left,.Footer__container__right{
                width: 100%;
            }
    
    
            .Footer__container__left{
                display: none;
            }
    
    
            .Footer__container__right{
                padding-top: 2rem;
                padding-inline: 1.25rem;

                .Footer__container__right_form{
                    
                    h3{
                        line-height: 1;
                        @include fs(40);
                    }
                }
    
                .contactlinks{
                    padding-top: 2rem;
                    padding-bottom: 2rem;

                    ul{
                        padding-left: 1.25rem;
    
                        li{
                           
                            @include fs(16);
                            width: fit-content;
                            margin: 0 auto;
                            
    
                            &::marker{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .Footer__copyright{
            flex-direction: column;
            gap: 1rem;
            padding-bottom: 3rem;
        }
    }


}


@media only screen and (min-width:0px) and (max-width:660px){

    .Footer{
        padding-top: 5rem;
    
        .Footer__container{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            flex-direction: column;
    
    
            .Footer__container__left,.Footer__container__right{
                width: 100%;
            }
    
    
            .Footer__container__left{
                img{
                    
                    width: 100%;
                }
            }
    
    
            .Footer__container__right{
                padding-top: 2rem;
                padding-inline: 1.25rem;

                .Footer__container__right_form{
                    
                    h3{
                        line-height: 1;
                        @include fs(40);
                    }
                }
    
                .contactlinks{
                    padding-top: 2rem;
                    padding-bottom: 2rem;

                    ul{
                        padding-left: 1.25rem;
    
                        li{
                           
                            @include fs(16);
                            
                            
    
                            &::marker{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .Footer__copyright{
            flex-direction: column;
            gap: 1rem;
            padding-bottom: 3rem;
        }
    }


}