.FabricoProprio{

    background-image: url(../media/fabricoproprio/fundobroa.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 38%;
    min-height: 100vh;
    position: relative;

    &.sobremesa{
        background-image: url(../media/fabricoproprio/sobremesa.jpg);
    }
    svg.rotate{
        position: absolute;
        top: 0%;
        transform: translate(-50%,-50%);
        animation: rotate 50s ease infinite;
    }

    .FabricoProprio__container{
        padding-top: 160px;
        padding-bottom: 200px;
        padding-left: 250px;

        .FabricoProprio__container__content_left{
            max-width: 50%;

                h1{
                    font-family: 'GreatlyStamp';
                    @include fs(75);
                    color: $castanhoEscuro;
                    font-weight: 400;
                    position: relative;
                    line-height: 0.6;
                    
                    &::after{
                        content: url(../media/fabricoproprio/machescuro.svg);
                        position: absolute;
                        bottom: -50px;
                        left: -40px;
                        width: 100%;
                    }
                    margin-bottom: 4rem;
                }

                p{
                    max-width: 470px;
                    padding-top: 1rem;
                    @include fs(16);
                    color: $castanhoEscuro;
                }
                
        }
    }


}

@media only screen and (min-width:1440px) and (max-width:1600px){

    .FabricoProprio{

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 38%;
        min-height: 100vh;
        position: relative;
    
        svg.rotate{
            position: absolute;
            top: 0%;
            transform: translate(-50%,-50%);
            animation: rotate 50s ease infinite;
        }
    
        .FabricoProprio__container{
            padding-top: 10rem;
            padding-bottom: 12.5rem;
            padding-left: 10.625rem;
    
            .FabricoProprio__container__content_left{
                max-width: 50%;
    
                    h1{
                        font-family: 'GreatlyStamp';
                        @include fs(64);
                        color: $castanhoEscuro;
                        font-weight: 400;
                        position: relative;
                        line-height: 0.6;
                        
                        &::after{
                            content: url(../media/fabricoproprio/machescuro.svg);
                            position: absolute;
                            bottom: -3.125rem;
                            left: -2.5rem;
                            width: 100%;
                        }
                        margin-bottom: 4rem;
                    }
    
                    p{
                        max-width: 470px;
                        padding-top: 1rem;
                        @include fs(16);
                        color: $castanhoEscuro;
                    }
                    
            }
        }
    
    
    }

}

@media only screen and (min-width:1260px) and (max-width:1439px){

    .FabricoProprio{

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 40%;
        min-height: 100vh;
        position: relative;
    
        svg.rotate{
            position: absolute;
            top: 0%;
            transform: translate(-50%,-50%);
            animation: rotate 50s ease infinite;
        }
    
        .FabricoProprio__container{
            padding-top: 10rem;
            padding-bottom: 12.5rem;
            padding-left: 8rem;
    
            .FabricoProprio__container__content_left{
                max-width: 40%;
    
                    h1{
                        font-family: 'GreatlyStamp';
                        @include fs(64);
                        color: $castanhoEscuro;
                        font-weight: 400;
                        position: relative;
                        line-height: 0.6;
                        
                        &::after{
                            content: url(../media/fabricoproprio/machescuro.svg);
                            position: absolute;
                            bottom: -3.125rem;
                            left: -2.5rem;
                            width: 100%;
                        }
                        margin-bottom: 4rem;
                    }
    
                    p{
                        max-width: 440px;
                        padding-top: 1rem;
                        @include fs(16);
                        color: $castanhoEscuro;
                    }
                    
            }
        }
    
    
    }

}

@media only screen and (min-width:1024px) and (max-width:1259px){

    .FabricoProprio{

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 40%;
        min-height: 100vh;
        position: relative;
    
        svg.rotate{
            position: absolute;
            top: 0%;
            transform: translate(-50%,-50%);
            animation: rotate 50s ease infinite;
        }
    
        .FabricoProprio__container{
            padding-top: 8rem;
            padding-bottom: 12.5rem;
            padding-left: 6rem;
    
            .FabricoProprio__container__content_left{
                max-width: 40%;
    
                    h1{
                        font-family: 'GreatlyStamp';
                        @include fs(64);
                        color: $castanhoEscuro;
                        font-weight: 400;
                        position: relative;
                        line-height: 0.6;
                        
                        &::after{
                            content: url(../media/fabricoproprio/machescuro.svg);
                            position: absolute;
                            bottom: -3.125rem;
                            left: -2.5rem;
                            width: 100%;
                        }
                        margin-bottom: 4rem;
                    }
    
                    p{
                        max-width: 440px;
                        padding-top: 1rem;
                        @include fs(16);
                        color: $castanhoEscuro;
                    }
                    
            }
        }
    
    
    }

}


@media only screen and (min-width:601px) and (max-width:1023px){

    .FabricoProprio{

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 40%;
        min-height: 100vh;
        position: relative;
    
        svg.rotate{
            position: absolute;
            top: 0%;
            transform: translate(-50%,-50%);
            animation: rotate 50s ease infinite;
        }
    
        .FabricoProprio__container{
            padding-top: 8rem;
            padding-bottom: 12.5rem;
            padding-left: 4rem;
    
            .FabricoProprio__container__content_left{
                max-width: 40%;
    
                    h1{
                        font-family: 'GreatlyStamp';
                        @include fs(64);
                        color: $castanhoEscuro;
                        font-weight: 400;
                        position: relative;
                        line-height: 0.6;
                        
                        &::after{
                            content: url(../media/fabricoproprio/machescuro.svg);
                            position: absolute;
                            bottom: -3.125rem;
                            left: -2.5rem;
                            width: 100%;
                        }
                        margin-bottom: 4rem;
                    }
    
                    p{
                        max-width: 440px;
                        padding-top: 1rem;
                        @include fs(16);
                        color: $castanhoEscuro;
                    }
                    
            }
        }
    
    
    }

}


@media only screen and (min-width:501px) and (max-width:600px){
  
    
    .FabricoProprio{

        background-image: url(../media/fabricoproprio/broamobile.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 0%;
        min-height: 130vh;
        position: relative;

        
        &.sobremesa{
            background-image: url(../media/fabricoproprio/sobremesaMobile.jpg);
        }
        
    
        svg.rotate{
            display: none;
        }
    
        .FabricoProprio__container{
            padding-top: 5rem;
            padding-bottom: 10rem;
            padding-left: 1.25rem;
    
            .FabricoProprio__container__content_left{
                max-width: 70%;
                margin: 0 auto;
                
                

                h1{            
                    @include fs(64);
                    text-align: center;
        
                    &::after{
                        content: url(../media/fabricoproprio/machescuro.svg);
                        position: absolute;
                        bottom: -50px;
                        left: -2rem;
                        right: 0;
                        transform: scale(0.7);
                        width: 100%;
                    }
                    
                }
    
                    p{
                        max-width: unset;
                        @include fs(16);
                    }               
            }
        }
    }
}

@media only screen and (min-width:360px) and (max-width:500px){
  
    
    .FabricoProprio{
        background-color: #EFD1AF;
        background-image:unset;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 0%;
        min-height: unset;
        position: relative;
        transition: background-color .6s ease;
        
        &.sobremesa{
            background-image: unset;
            background-color: #BBD2D8;
        }
        
    
        svg.rotate{
            display: none;
        }
    
        .FabricoProprio__container{
            padding-top: 5rem;
            padding-bottom: 5rem;
            padding-left: 1.25rem;
    
            .FabricoProprio__container__content_left{
                max-width: 70%;
                margin: 0 auto;
                
                

                h1{            
                    @include fs(64);
                    text-align: center;
        
                    &::after{
                        content: url(../media/fabricoproprio/machescuro.svg);
                        position: absolute;
                        bottom: -50px;
                        left: -2rem;
                        right: 0;
                        transform: scale(0.7);
                        width: 100%;
                    }
                    
                }
    
                    p{
                        max-width: unset;
                        @include fs(14);
                    }               
            }
        }
    }
}


@media only screen and (min-width:0px) and (max-width:359px){
  
    
    .FabricoProprio{

        background-image: unset;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom 0%;
        min-height: unset;
        position: relative;
        background-color: #EFD1AF;
        transition: background-color .6s ease;

        
        &.sobremesa{
            background-image: unset;
            background-color: #BBD2D8;

        }
        
    
        svg.rotate{
            display: none;
        }
    
        .FabricoProprio__container{
            padding-top: 5rem;
            padding-bottom: 10rem;
            padding-left: 1.25rem;
    
            .FabricoProprio__container__content_left{
                max-width: 70%;
                margin: 0 auto;
                
                

                h1{            
                    @include fs(64);
                    text-align: center;
        
                    &::after{
                        content: url(../media/fabricoproprio/machescuro.svg);
                        position: absolute;
                        bottom: -50px;
                        left: -2rem;
                        right: 0;
                        transform: scale(0.7);
                        width: 100%;
                    }
                    
                }
    
                    p{
                        max-width: unset;
                        @include fs(14);
                    }               
            }
        }
    }
}












@keyframes rotate{
    0%{
        transform: translate(-50%,-50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%,-50%) rotate(360deg);
    }
}