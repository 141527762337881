
// Passar px to rem
@mixin fs($size) {
  font-size: $size / 16 + rem;
}


@mixin padInline($size) {
  padding-inline: $size / 16 + rem;
}
