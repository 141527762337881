.hero_padaria{

    background-image: url(../media/hero/fundo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
    animation: move 40s ease;
    
    .hero_padaria__container{
        width: 100%;
        padding-top: 21.25rem;
        padding-bottom: 26.25rem;
        padding-left: 10rem;

        .logotipo{
            position: absolute;
            top: 2.5rem;
            left: 6.875rem;
        }

        .hero_padaria__container__content{

            h1.hero_padaria__container__content__title{
                font-family: 'GreatlyStamp';
                @include fs(75);
                color: $branco;
                font-weight: 400;
                position: relative;
                transform: rotate(-3.88deg);
                line-height: 1;
                &::after{
                    content: url(../media/hero/mach.svg);
                    position: absolute;
                    bottom: -6.875rem;
                    left: -2.5rem;
                    width: 100%;
                    transform: rotate(3.88deg);
                }
            }
        }
    }
    
}


@media only screen and (min-width:1440px) and (max-width:1600px){

    .hero_padaria{

        background-image: url(../media/hero/fundo.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        overflow: hidden;
        
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 15.25rem;
            padding-bottom: 20.25rem;
            padding-left: 10rem;
    
            .logotipo{
                position: absolute;
                top: 2.5rem;
                left: 6.875rem;
            }
    
            .hero_padaria__container__content{
    
                h1.hero_padaria__container__content__title{
                    font-family: 'GreatlyStamp';
                    @include fs(64);
                    color: $branco;
                    font-weight: 400;
                    position: relative;
                    transform: rotate(-3.88deg);
                    line-height: 1;
                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -6.875rem;
                        left: -2.5rem;
                        width: 100%;
                        transform: rotate(3.88deg);
                    }
                }
            }
        }
        
    }
    
}

@media only screen and (min-width:1260px) and (max-width:1439px){

    .hero_padaria{

        background-image: url(../media/hero/fundo.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        overflow: hidden;
        
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 15.25rem;
            padding-bottom: 20.25rem;
            padding-left: 8rem;
    
            .logotipo{
                position: absolute;
                top: 2.5rem;
                left: 6.875rem;
            }
    
            .hero_padaria__container__content{
    
                h1.hero_padaria__container__content__title{
                    font-family: 'GreatlyStamp';
                    @include fs(60);
                    color: $branco;
                    font-weight: 400;
                    position: relative;
                    transform: rotate(-3.88deg);
                    line-height: 1;
                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -6.875rem;
                        left: -2.5rem;
                        width: 100%;
                        transform: rotate(3.88deg);
                    }
                }
            }
        }
        
    }
    
}

@media only screen and (min-width:1024px) and (max-width:1259px){

    .hero_padaria{

        background-image: url(../media/hero/fundo.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        overflow: hidden;
        
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 15.25rem;
            padding-bottom: 20.25rem;
            padding-left: 6rem;
    
            .logotipo{
                position: absolute;
                top: 2.5rem;
                left: 6.875rem;
            }
    
            .hero_padaria__container__content{
    
                h1.hero_padaria__container__content__title{
                    font-family: 'GreatlyStamp';
                    @include fs(54);
                    color: $branco;
                    font-weight: 400;
                    position: relative;
                    transform: rotate(-3.88deg);
                    line-height: 1;
                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -6.875rem;
                        left: -2.5rem;
                        width: 100%;
                        transform: rotate(3.88deg);
                    }
                }
            }
        }
        
    }
    
}



@media only screen and (min-width:601px) and (max-width:1024px){

    .hero_padaria{

        background-image: url(../media/hero/fundo.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        overflow: hidden;
        
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 15.25rem;
            padding-bottom: 20.25rem;
            padding-left: 5rem;
    
            .logotipo{
                position: absolute;
                top: 2.5rem;
                left: 6.875rem;
            }
    
            .hero_padaria__container__content{
    
                h1.hero_padaria__container__content__title{
                    font-family: 'GreatlyStamp';
                    @include fs(54);
                    color: $branco;
                    font-weight: 400;
                    position: relative;
                    transform: rotate(-3.88deg);
                    line-height: 1;
                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -6.875rem;
                        left: -8rem;
                        width: 100%;
                        transform: rotate(3.88deg) scale(0.5);
                    }
                }
            }
        }
        
    }
    
}

@media only screen and (min-width:501px) and (max-width:600px){



    .hero_padaria{
        background-position: center;
       
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 20rem;
            padding-bottom: 20rem;
            padding-left: 1.25rem;
    
            .logotipo{
                width: 100%;
                position: absolute;
                top: 5rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                img{
                    max-height: 80px;
                }
            }
    
            .hero_padaria__container__content{
                text-align: center;
                h1.hero_padaria__container__content__title{
                    @include fs(46);
                    

                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -4.875rem;
                        left: .5rem;
                        right: 0;
                        width: 50%;
                        
                        transform: rotate(3.88deg) scale(0.5);
                    }
                }
            }
        }   
    }
}

@media only screen and (min-width:360px) and (max-width:500px){



    .hero_padaria{
        background-position: center;
       
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 20rem;
            padding-bottom: 20rem;
            padding-left: 1.25rem;
    
            .logotipo{
                width: 100%;
                position: absolute;
                top: 5rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                img{
                    max-height: 80px;
                }
            }
    
            .hero_padaria__container__content{
                text-align: center;
                h1.hero_padaria__container__content__title{
                    @include fs(42);
                    

                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -4.875rem;
                        left: -2rem;
                        right: 0;
                        width: 50%;
                        
                        transform: rotate(3.88deg) scale(0.5);
                    }
                }
            }
        }   
    }
}

@media only screen and (min-width:0px) and (max-width:359px){



    .hero_padaria{
        background-position: center;
       
        
        .hero_padaria__container{
            width: 100%;
            padding-top: 15rem;
            padding-bottom: 15rem;
            padding-left: 0.85rem;
            padding-left: 0.85rem;
    
            .logotipo{
                width: 100%;
                position: absolute;
                top: 5rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                img{
                    max-height: 80px;
                }
            }
    
            .hero_padaria__container__content{
                text-align: center;
                h1.hero_padaria__container__content__title{
                    @include fs(38);
                    

                    &::after{
                        content: url(../media/hero/mach.svg);
                        position: absolute;
                        bottom: -4.875rem;
                        left: -2rem;
                        right: 0;
                        width: 50%;
                        
                        transform: rotate(3.88deg) scale(0.40);
                    }
                }
            }
        }   
    }
}