.PopupDownload{

    display: none;
    position: fixed;
    top: 0%;
    left: 0;
    min-height: 100vh;
    width: 100%;
    transform: translateX(-100%);

    &.active{
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(0);
        background-color: rgba(40, 40, 46, 0.899);


        .PopupDownload__container{

            max-width: 500px;
            border-radius: 5px;
            margin: 0 auto;
            background-color: $branco;
            padding: 4rem 4rem;

            position: relative;

            .PopupDownload__container_content{

                
                .logo_close{
                  
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 2rem;

                    .logo{
                        position: absolute;
                        top: 20px;
                        left: 20px;

                        img{
                            filter: invert(1);
                            max-width: 80px;
                        }
                    }


                    .close{
                        position: absolute;
                        top: 5px;
                        right: 20px;

                        p{
                            color: $textos;
                            font-size: 3rem;
                            cursor: pointer;
                            transition: color .6s ease;

                            &:hover{
                                color: #000;
                            }
                        }
                        
                    }
                }

                .headingForm{
                    padding-top: 1rem;
                    
                    h1{
                        font-size: 2rem;
                        text-align: center;
                        
                    }
    
                    p{
                        padding-top: 1rem;
                        font-size: 1rem;
                        text-align: center;
                    }

                }

              

                .formulario{
                    padding-top: 1.25rem;
                    

                    label,input{
                        display: block;
                        width: 100%;
                    }

                   

                    input{
                        padding: 4px;
                        border: 1px solid $titulosProdutos;
                        background-color: #3d3c3c;
                        color: $branco;
                        border-radius: 5px;
                        font-size: 16px;

                        &::placeholder{
                            font-size: 13px;
                            color: rgb(162, 162, 162);
                        }


                    }
                }

                .PopupDownload__container_content_download{
                    padding-top: 1.5rem;
                    
                    input{
                        background-color: #c7a07c;
                        padding: 8px 15px;
                        width: fit-content;
                        color: $branco;
                        border-radius: 5px;
                        font-weight: 400;
                        cursor: pointer;
                        border: none;
                        font-size: 0.9rem;

                        // fix iOS styling 
                        -webkit-appearance: none;

                        transition: background-color .6s ease;
                        &:hover{
                            background-color: #644123;
                        }
                    }
                }


                .AfterSubmit{
                    text-align: center;
                    padding-top: 20px;
                    &.dnone{
                        display: none;
                    }

                    a{
                        padding: 6px 20px;
                        -webkit-appearance: none;
                        border-radius: 5px;
                        color: $branco;
                        background-color: $titulosProdutos;
                        font-size: 16px;
                        cursor: pointer;
                        display: inline-block;
                        
                    }
                }
            }
        }
    }
}




@media only screen and (min-width:0px) and (max-width:350px){

    .PopupDownload{
        &.active{
            .PopupDownload__container{
                .PopupDownload__container_content{              
                    .logo_close{      
                        .logo{    
                            img{
                                max-width: 60px;
                            }
                        }
                    }
    
                    .headingForm{
                        
                        h1{
                            font-size: 1.6rem;
                            
                        }
        
                        p{
                            font-size: 0.9rem;
                        }
    
                    }
    
               
    
                    .PopupDownload__container_content_download{
                        input{
                            margin: 0 auto;      
                        }
                    }
                }
            }
        }
    }
    
}

@media only screen and (max-width:450px){

    .PopupDownload{
        &.active{
            .PopupDownload__container{
                max-width: 450px;
                padding: 4rem 2rem;
                margin-inline: 20px;
            }
        }
    }

}




