
/* Linha de fundo */
::-webkit-scrollbar-track {
    background: $textos;
}

/* barra que se move */
::-webkit-scrollbar-thumb {
    background: $tomPastel;
    transition: all 0.6s ease;
     border-radius: 5px;
}

/* hover barra */
::-webkit-scrollbar-thumb:hover {
    background: $castanhoEscuro;
    transition: all 0.6s ease;
}


::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
}